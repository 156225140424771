import React, {useState, useEffect} from "react";
import "./EventListing.css";
import {getFullPath, infrequentCacheConf, useBackend} from "../../../utility/Backend";
import LoadingScreen from "../../../components/LoadingScreen";
import Dropdown from "../../../components/Dropdown";
import {capitalizeFirstLetter, useUpdateSearchParams} from "../../../utility/Utilities";
import EventEntry from "../../../components/EventEntry";
import { useAddVideoToAction } from "../../../utility/Utilities";
import Config from "../../../utility/Config";
import SearchResults from "../../../components/SearchResults";

//FIXME Probably better if this was defined somewhere else, as it's being reused, but refactor later
export function createDateFilter (query, searchParams, updateSearchParams) {

    const seasons = Config.seasons;
    let season = searchParams.get("season") || "all";
    if (season !== "all") {
        // Sanitize input to only allow pre-selected options
        if (!seasons.includes(season)) season = seasons[0];
        const [start, end] = Config.seasonInterval(season)
        query["from_date"] = start.toISOString();
        query["to_date"] = end.toISOString();
    }

    return [query, (
        <div>
            <Dropdown
                centered
                title="Season"
                special="all"
                fallback="all"
                selected={season}
                options={seasons}
                onChange={v => updateSearchParams("season", v)}/>
        </div>
    )];
}

function createTeamFilter (query, team, teams, updateSearchParams) {

    if (team) {
        query["tags"] = {team: {id: team}};
    }
    if (teams === null) {
        return [query, null];
    }

    let options = Object.keys(teams);
    options.sort((a,b) => (teams[a].name > teams[b].name) ? 1 : -1);

    return [query, (
        <div>
            <Dropdown
                title="Team"
                special="all"
                fallback="all"
                selected={team}
                options={options}
                render={(id) => teams[id].name}
                btnRender={(id) => teams[id].short_name || teams[id].name}
                onChange={v => updateSearchParams({"team": v, "player": null,"player_action": null})}/>
        </div>
    )];
}

const ignoredTags = ["end of game", "start phase", "end phase", "throw-in", "highlights"];
const ignoredProps = ["after set piece"]
const oppositeTeamProp = ["offending player", "keeper"];

function createTagFilters (query, tags, players, searchParams, updateSearchParams) {

    // For this function to make any sense, have a look at the /tags API endpoint, which defines all possible tags

    let filters = [];
    // Selected tag & from the URL
    let tagParam = searchParams.get("tag");
    const playerId = parseInt(searchParams.get("player"), 10) || null;

    const validTags = Object.keys(tags.tags).filter(t => !ignoredTags.includes(t));
    
    // Find goal index, and put the additional tags after it
    const goalTagIndex = validTags.indexOf("goal");
    validTags.splice(goalTagIndex + 1, 0, "assist")
    validTags.splice(goalTagIndex + 2, 0, "saves")
    validTags.splice(goalTagIndex + 3, 0, "conceded goals")
    
    // Sanitize input to only allow the tags we wish to support
    if (tagParam && !validTags.includes(tagParam)) tagParam = null;

    const queryKeys = []; // Gets updated further down as we iterate through the props

    function onSwapTag (v) {
        // Delete all the query parameters specific to this tag, if they exist
        console.log("Swapping tag from", tagParam, "to", v);
        const q = queryKeys.reduce((agg, key) => {
            agg[key] = null;
            return agg;
        }, {"tag": v})
        updateSearchParams(q);
    }

    filters.push(
        <div key="tag">
            <Dropdown
                centered
                title="Tag"
                fallback="all"
                special="all"
                selected={tagParam}
                options={validTags}
                onChange={onSwapTag} />
        </div>
    );
    if (tagParam === null) return [query, filters];

    const isAssistTag = tagParam === "assist"
    const isSavesTag = tagParam === "saves"
    const isConcededGoalsTag = tagParam === "conceded goals"

    // The tag used for the query. This is the same as the tagParam, except for assists and saves
    let tag = tagParam;

    // Using goal tag, because assists are part of goal event
    if (isAssistTag) tag = "goal"

    // Using shot tag, because saves are part of shot event
    if (isSavesTag) tag = "shot"

    // Using goal tag, because conceded goals are part of goal event
    if (isConcededGoalsTag) tag = "goal"

    // We make a copy. It is possible that team is already set
    let q = {...query["tags"], action: tag};

    // Players that will be listed in the players filter
    let playersForFilter = players
    
    // If "saves" tag is selected, only show goalkeepers
    if (players && (isSavesTag || isConcededGoalsTag)) playersForFilter = Object.values(players)
        .filter(p => p.role === "goalkeeper")
        .reduce((agg, p) => {agg[p.id] = p; return agg;}, {});

    let playerList = [];
    if (players !== null) {
        playerList = Object.keys(playersForFilter);
        playerList.sort((a,b) => (playersForFilter[a].name > playersForFilter[b].name) ? 1 : -1);
    }

    // Add player properties
    let playerProps = tags.tags[tag].reduce((agg, {name, type}) => {
        if (type === "player") agg.push(name);
        return agg;
    }, []);
    
    // Include/exclude assist properties, since assist should behave as it's own event.
    // Assist props are: Football="assist by" and Hockey="assist1","assist2". Searching for "assist" substring covers all.
    if (isAssistTag) playerProps = playerProps.filter((p) => p.includes("assist"))
    else playerProps = playerProps.filter((p) => !p.includes("assist"))

    // Includes keeper props for "saves" and "conceded goals" tags
    if (isSavesTag) playerProps = playerProps.filter((p) => p.includes("keeper"))
    if (isConcededGoalsTag) playerProps = playerProps.filter((p) => p.includes("keeper"))

    const renderPlayer = (id) => (
        <span><span className="inline-shirt">{players[id].shirt_number || ""}</span> {players[id].name}</span>
    );
    const renderShortPlayer = (id) => (
        <span><span className="inline-shirt">{players[id].shirt_number || ""}</span> {players[id].last_name || players[id].name}</span>
    );

    if (playerProps.length > 0) {
        queryKeys.push("player_action"); // We keep "player"

        if (players === null) {
            filters.push(
                <div key="player">
                    <Dropdown
                        title="Player"
                        disabled
                        fallback="<pick team>" />
                </div>
            );
        } else {
            filters.push(
                <div key="player">
                    <Dropdown
                        title="Player"
                        fallback="all"
                        special="all"
                        selected={playerId}
                        options={playerList}
                        btnRender={renderShortPlayer}
                        render={renderPlayer}
                        onChange={v => updateSearchParams("player", v)} />
                </div>
            );

            if (playerId) {
                const fallback = playerProps[0];
                if (playerProps.length === 1) {
                    q[fallback] = {id: playerId};
                } else {
                    let prop = searchParams.get("player_action") || fallback;
                    if (!playerProps.includes(prop)) prop = fallback;
                    q[prop] = {id: playerId};
                    filters.push(
                        <div key="player_action">
                            <Dropdown
                                title="Player action"
                                fallback={fallback}
                                selected={prop}
                                options={playerProps}
                                onChange={v => updateSearchParams("player_action", v)} />
                        </div>
                    );
                }
            }
        }
    }

    // The filters that are specific to the tag
    let tagFilters = tags.tags[tag]
    
    if (tagParam === "saves") {
        // Remove tags that are not relevant for saves
        tagFilters = tagFilters.filter((tag) => tag.name !== "on target" && tag.name !== "shot type" && tag.name !== "shot result")
        
        // if "all" is selected in player filter, we regenerate the whole query
        if (!playerId && playersForFilter) {
            const allGoalkeepers = Object.keys(playersForFilter)
            q = allGoalkeepers.map((id) => {
                return {"action":"shot", "shot result":{"value":"saved"}, "keeper":{"id": parseInt(id)}}
            })
        } 
        // else, only add shot result to have "saved"
        else {
            q["shot result"] = {value: "saved"}
        } 
    }

    if (tagParam === "conceded goals") {
        // if "all" is selected in player filter, we regenerate the whole query
        if (!playerId && playersForFilter) {
            const allGoalkeepers = Object.keys(playersForFilter)
            q = allGoalkeepers.map((id) => {
                return {"action":"goal", "keeper":{"id": parseInt(id)}}
            })
        }  
    }

    // Add custom tags. These are easy, they define all the possible options as a list of strings
    tagFilters.forEach((({name, type}) => {
        const typedef = tags.types[type];
        if (!typedef.custom || typedef.options.length === 0 || ignoredProps.includes(name)) return;

        const key = `${tag}_${name}`;
        queryKeys.push(key);
        let value = searchParams.get(key);
        if (value && (!typedef.options.includes(value))) value = null;

        if (value) q[name] = {value};
        filters.push(
            <div key={key}>
                <Dropdown
                    centered
                    title={capitalizeFirstLetter(name)}
                    fallback="all"
                    special="all"
                    selected={value}
                    options={typedef.options}
                    onChange={v => updateSearchParams(key, v)} />
            </div>
        );
    }));

    // If we're searching for own goals and have set a scorer
    if (q.action === "goal" && q["shot type"] && q["shot type"]["value"] === "own goal" && q["scorer"]) {
        delete q["team"];
    }

    // Some properties work on the opposite team. In this case we cannot find them if we include team prop
    if (oppositeTeamProp.some(p => q[p])) {
        delete q["team"];
    }

    return [{...query, tags: q}, filters];
}

export function createRatingFilter (query, searchParams, updateSearchParams) {

    const ratings = [1,2,3,4,5]
    const rating = searchParams.get("rating") || 1
    query["min_rating"] = rating

    return [query, (
        <div>
            <Dropdown
                centered
                title="Rating"
                fallback={1}
                selected={rating}
                options={ratings}
                onChange={v => updateSearchParams("rating", v)}/>
        </div>
    )];
}

function EventListing ({tags}) {

    const [
        selectedVideos, 
        addVideoToList, 
        removeVideoFromList, 
        clearSelectedList, 
    ] = useAddVideoToAction();
    
    const [asc, setAsc] = useState(false);
    const [searchParams, updateSearchParams,] = useUpdateSearchParams();
    const tag = searchParams.get("tag")
    const isSef = Config.association === "SEF"

    useEffect(() => {
        updateSearchParams("rating")
    }, [tag])

    let team = parseInt(searchParams.get("team"), 10) || null;
    let apiQuery = {};
    let dateFilter, teamFilter, tagFilters, ratingFilter;

    if (asc) apiQuery.asc = true;

    [apiQuery, dateFilter] = createDateFilter(apiQuery, searchParams, updateSearchParams);

    let teams = null;
    const {data: activeTeams} = useBackend(
        "/team/active_teams",
        apiQuery,
        infrequentCacheConf);
    if (activeTeams) {
        teams = activeTeams["teams"].reduce((agg, t) => {agg[t.id] = t; return agg;}, {});
        if (team && !teams[team]) team = null;
    }

    let players = null;
    const {data: activePlayers} = useBackend(
        team ? ("/team/" + team + "/active_players") : null,
        apiQuery,
        infrequentCacheConf);
    if (activePlayers) {
        players = activePlayers["active_players"].reduce((agg, p) => {agg[p.player.id] = p.player; return agg;}, {});
    }

    [apiQuery, teamFilter] = createTeamFilter(apiQuery, team, teams, updateSearchParams);
    [apiQuery, tagFilters] = createTagFilters(apiQuery, tags, players, searchParams, updateSearchParams);
    [apiQuery, ratingFilter] = createRatingFilter(apiQuery, searchParams, updateSearchParams);

    // function clearFilters () {
    //     setAsc(false);
    //     resetAllSearchParamsExcept(["editing"]);
    // }

    let fullPath;
    // If we're missing data, dont perform the request
    if (teams === null || (team && players === null)) fullPath = null;
    else fullPath = getFullPath("/event", apiQuery);

    const editing = searchParams.get("editing");
    let editingType, editingId;
    if (editing) [editingType, editingId] = editing.split("_");

    const renderItem = (e, idx) => (
        <EventEntry key={e.id}
                    event={e}
                    active={e.id === editingId && editingType === "event"}
                    tabIndex={20+idx} 
                    selectedVideos={selectedVideos}
                    addVideoToList={addVideoToList}
                    removeVideoFromList={removeVideoFromList}/>
    );

    return (
        <div className="event-listing">
            <div className="event-filters">
                {dateFilter}
                {teamFilter}
                {isSef && ratingFilter}
                {tagFilters}
                {/* <div className="filter-controls">
                    <button title="Reset" onClick={clearFilters}><MdOutlineRemoveCircleOutline /></button>
                    {asc && (
                        <button title="Sort descending" onClick={() => setAsc(false)}><FaChevronUp /></button>
                    )}
                    {!asc && (
                        <button title="Sort ascending" onClick={() => setAsc(true)}><FaChevronDown /></button>
                    )}
                </div> */}
            </div>
            <SearchResults 
                key={fullPath} 
                path={fullPath} 
                render={renderItem}
                addVideoToList={addVideoToList}
                removeVideoFromList={removeVideoFromList}
                selectedVideos={selectedVideos} 
                clearSelectedList={clearSelectedList}/>
        </div>
    );
}

export default function EventListingWrapper () {
    const {data} = useBackend("/tag", undefined, {revalidateIfStale: false, revalidateOnFocus: false});
    if (!data) return <LoadingScreen />;

    return <EventListing tags={data}/>;
}
